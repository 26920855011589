import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import historySlice from "./slices/historySlice";
import tableStateSlice from "./slices/tableStateSlice";
import authUserSlice from "./slices/authUserSlice";
import pursuitSlice from "./slices/pursuitSlice";
import { api as buildingsApi } from "./api/buildings";
import { api as serviceApi } from "./api/services";
import { api as servicesTsApi } from "./api/serviceApi";
import { api as pursuitApi } from "./api/pursuits";
import { api as userApi } from "./api/users";
import { api as suppliersApi } from "./api/suppliers";
import { api as formulasApi } from "./api/formulas";
import { api as projectsApi } from "./api/projects";
import { onboardingApi } from "./api/onboarding";

import {
  loadStoreValue,
  STORAGE_KEY_USER_DATA,
  STORAGE_KEY_TOKEN,
  STORAGE_KEY_PAGE_TITLE,
} from "../utils/session-util";

const middlewares = [
  buildingsApi.middleware,
  pursuitApi.middleware,
  projectsApi.middleware,
  formulasApi.middleware,
  serviceApi.middleware,
  servicesTsApi.middleware,
  userApi.middleware,
  suppliersApi.middleware,
  onboardingApi.middleware,
];

export const store = configureStore({
  reducer: {
    historySlice,
    tableStateSlice: tableStateSlice.reducer,
    authUserSlice,
    pursuitSlice,
    [buildingsApi.reducerPath]: buildingsApi.reducer,
    [pursuitApi.reducerPath]: pursuitApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [formulasApi.reducerPath]: formulasApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [servicesTsApi.reducerPath]: servicesTsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [suppliersApi.reducerPath]: suppliersApi.reducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer,
  },
  preloadedState: {
    historySlice: {
      history: [],
      pageTitle: loadStoreValue(STORAGE_KEY_PAGE_TITLE),
    },
    authUserSlice: {
      data: loadStoreValue(STORAGE_KEY_USER_DATA),
      token: loadStoreValue(STORAGE_KEY_TOKEN),
      loading: false,
    },
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(middlewares),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
